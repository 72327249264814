import React, {useState, useEffect} from "react";
import loadable from "@loadable/component";
import "@src/scss/App.scss";
import Seo from "@components/seo";
import Layout from "@components/layout";
import { graphql } from 'gatsby'
import BannerRender from "@components/modules/Banner/BannerRender";
import { Routes } from '@components/routes';
import AwardCard from '@components/modules/Awards/award';
import ModuleSection from '@components/modules/moduleWrapper';
import BookValuation from '@components/modules/BookValuation';
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
// import Select from 'react-select';
import { Stack } from "react-bootstrap";
const Select = loadable(() => import("react-select"));
// import { useBreakpoint } from '@components/breakpoint.js';
const sortOptions = [
    { value: "", label: "All" },
    { value: "2022", label: "2022" },
    { value: "2021", label: "2021" },
    { value: "2020", label: "2020" },
    { value: "2019", label: "2019" },
    { value: "2018", label: "2018" },
    { value: "2017", label: "2017" },
    { value: "2016", label: "2016" },
    { value: "2015", label: "2015" },
]
const orderSelect = {
    option: (provided) => ({
        ...provided,
        color: "#5D5F66"
    }),
    control: (provided) => ({
        ...provided,
        background: "transparent",
        borderRadius: 0,
        border: "none",
        // height: 16,
        boxShadow: "none",
        // paddingLeft: 24,
        // paddingRight: 16
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        color: "#5D5F66"
    }),
    indicatorsContainer: (provided) => ({
        ...provided,
        marginTop: "-5px"
    }),
    indicatorSeparator: () => ({
        display: "none"
    }),
    placeholder: (provided) => ({
        ...provided,
        color: "#5D5F66",
        whiteSpace: "nowrap"
    }),
    singleValue: (provided) => {
        return {
            ...provided,
            color: "#5D5F66"
        };
    },
    // valueContainer: (provided) => ({
    //     ...provided,
    //     paddingLeft: 0,
    //     paddingRight: 0
    // })
}
const AwardsLandingPages = ({
    // pageContext,
    data,
    // location
}) => {
    const [sortyear, setsortyear] = useState(false)
    const refetch = (val) => {
        setsortyear(!sortyear)
        setAwards(awards?.filter(list => list?.Date.includes(val.sort))) 
    }
    const { glstrapi: { page, awards } } = data
    var dateArray = [{value: "", label: "All"}]
    const dates = awards.filter((d) => {
        var singleyear = {value: d?.Date?.substring(0, 4), label: d.Date?.substring(0, 4) }
        dateArray?.push(singleyear)
    })
    var pp = dateArray.filter( (ele, ind) => ind === dateArray.findIndex( elem => elem.value === ele.value && elem.value === ele.value))
    const breadcrumbs = [
        { label: "About", url: `${Routes.AboutPage}` },
        { label: page?.Pagename, url: `${Routes.AboutPage}/${page?.Slug}` }
    ]
    const [awardsdata, setAwards] = useState(awards)

    // const breakpoints = useBreakpoint();
    const postsPerPage = 16;
	let arrayForHoldingPosts = [];
	// const [] = useState(0);
	const [postsToShow, setPostsToShow] = useState([]);
	const [next, setNext] = useState(16);
	const loopWithSlice = (start, end) => {
        const slicedPosts = awardsdata?.slice(0, end);
        if(awardsdata){
            arrayForHoldingPosts = [...arrayForHoldingPosts, ...slicedPosts];
            setPostsToShow(arrayForHoldingPosts);
        }

	};

	const handleShowMorePosts = () => {
		loopWithSlice(next, next + postsPerPage);
		setNext(next + postsPerPage);
	};

	useEffect(() => {
		loopWithSlice(0, postsPerPage);
        setNext(16)
	}, [awardsdata?.length, sortyear]);
  
    return (
        <Layout popularSearch={page?.Popular_Search} bodyClass="partners-landing">
            <Seo
                title={page?.Metadata?.title ? page.Metadata.title : page.Title}
                description={page?.Metadata?.description ? page.Metadata.description : null}
                // image={page?.Metadata?.image?.url ? page.Metadata.image.url : null}
                image={page?.Metadata?.image?.url_sharp?.childImageSharp ? page.Metadata.image.url_sharp.childImageSharp.resize : null}
            />
            <BannerRender
                page={page}
                breadcrumbs={breadcrumbs}
            />
            
            <ModuleSection sectionSpacing="py-40 py-md-64 py-lg-80">
            <Stack gap={32} direction="horizontal" className="teams-filters mb-24 mb-md-40">
                        <div className="ms-auto">
                            <Select
                                placeholder="All"
                                options={pp}
                                defaultValue=""
                                onChange={(e) => refetch({ sort: e.value })}
                                styles={orderSelect}
                                isSearchable={false}
                            />
                        </div>
                    </Stack>
            <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 gy-24 gx-md-16 gy-md-30 g-lg-30">
                    {postsToShow?.map((award, i) => {
                       if (i === 7) {
                        return(
                            <React.Fragment key={`${sortyear ? "exclusive" : "exclusiveby"}-sortby-${i}`}>
                                <Col>
                            <AwardCard award={award} className="awards-slider__item grid-item" custom={i} />
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <BookValuation />
                            </Col> </React.Fragment>
                                )
                            } else {
                                return(
                                    <Col key={`${sortyear ? "exclusive" : "exclusiveby"}-sortby-${i}`}>
                                         <AwardCard award={award} className="awards-slider__item grid-item" custom={i} />
                                         </Col>
                                         )
                            }
                        })}
                </div>
                {postsToShow?.length !== awardsdata?.length &&
                            <Col xs={12} md={12} xxl={12} className="mt-16 mt-lg-40">
                                <Button
                                    variant="loadMore"
                                    // disabled={pageDetails && start + limit >= pageDetails?.totalCount}
                                    // onClick={() => refetch({ limit: setNext(next + postsPerPage) })}
                                    onClick={handleShowMorePosts}
                                    className="w-100"
                                >
                                    Load more
                                </Button>
                            </Col>
                        }
            </ModuleSection>
           
            
        </Layout>
    );
}

export default AwardsLandingPages

export const query = graphql`
query awadrsLandingQuery($id: ID!) {
    glstrapi {
        page(id: $id, publicationState: LIVE) {
            Pagename
            Title
            Slug
            HeaderTemplate
            Popular_Search
            imagetransforms
            BannerImage {
                ... on GLSTRAPI_ComponentModulesBannerImage {
                    ...ImageBannerFragment
                }
            }
            Metadata {
                title
                description
                image {
                    url
                    url_sharp {
                        childImageSharp {
                            resize(width: 1200) {
                                src
                                height
                                width
                            }
                        }
                    }
                }
            }
        }
        awards(publicationState: LIVE, sort: "Date:DESC") {
            BannerImage {
                ... on GLSTRAPI_ComponentModulesBannerImage {
                    id
                    ItemText
                    ItemTitle
                    Image {
                        url
                        alternativeText
                    }
                }
            }
            imagetransforms
            Date
            Slug
            id
          }
    }
}
`